import React from "react"

import { Heading, List, ListItem, Stack, Text } from "@kiwicom/orbit-components"
import { Check } from "@kiwicom/orbit-components/icons"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => (
  <DriveLayout title="Pre-Booked Stops">
    <Hero title="Pre-Booked Stops" />
    <Container size="medium">
      <EmberCard>
        <EmberCardSection>
          <Stack>
            <Heading type="title3">Overview</Heading>
            <Text>
              Most of our stops are pre-booked stops. This means we only call at
              the stop if someone has a booking, allowing us to serve more stops
              whilst keeping end-to-end journeys fast.
            </Text>
            <Text>
              Pre-booked stops have a cut-off time, usually 10 minutes, at which
              point we close online bookings. This means we know by this point
              whether or not we can skip this stop. If we can skip it, the iPad
              will jump to show the next stop. Note that the cut-off time is
              relative to the scheduled time so if you are running late then you
              may find the iPad is skipping stops far in advance.
            </Text>
            <Text>
              You should always follow the next stop shown on the iPad, even if
              it is a pre-booked stop and there are no bookings showing.
            </Text>
            <Text>
              In this situation, there might be passengers waiting at the stop
              who booked a ticket, even if you can't see them on the iPad. For
              example, if your iPad has lost its internet connection it won't
              show any bookings even if someone has booked and is waiting at the
              stop. This doesn't happen often, but we don't want to risk leaving
              passengers behind. The iPad is conservative and only skips stops
              when it is certain there are no passengers booked at the stop.
            </Text>
            <Heading type="title3">Departing Early</Heading>
            <Text>
              You can depart a pre-booked only stop before the scheduled
              departure time provided that:
            </Text>
            <List>
              <ListItem icon={<Check />}>
                You have picked up and dropped off all passengers using the
                stop. You must wait until the scheduled departure time if a
                passenger you're expecting hasn't turned up yet. Remember to
                wait until the end of the scheduled departure minute ("the magic
                minute")".
              </ListItem>
              <ListItem icon={<Check />}>
                You have marked any passengers that have not shown up as "No
                Shows". You should only do this after you've waited for the
                scheduled departure to pass.
              </ListItem>
              <ListItem icon={<Check />}>
                You've had a good look for other passengers wanting to use the
                service. Remember, it is possible in rare circumstances for the
                iPad not to show someone who has actually booked from the stop.
              </ListItem>
              <ListItem icon={<Check />}>
                In the unlikely event that you arrived at the stop before the
                booking cut-off time, you have waited until the depart button
                has turned green. Under no circumstances should you depart
                before the depart button has turned green or before the booking
                cut-off time.
              </ListItem>
              <ListItem icon={<Check />}>
                You have correctly made sure that the service has departed and
                moved to the next stop on the iPad. If departure isn't recorded
                and you leave the stop early the system will still allow for
                bookings up until the scheduled departure time.
              </ListItem>
            </List>
            <Heading type="title3">Timings</Heading>
            <Text>
              Pre-booked stops have to be timed differently from traditional
              stops because you won't call at every stop on every service.
            </Text>
            <Text>
              Including the whole time for the detour to the stop would cause
              services to run early all the time. Adding no time for diversion
              would cause services to run late all the time. We work out how
              likely we are to call at each stop and add enough time so that we
              are usually within five minutes of the scheduled time.
            </Text>
            <Text>
              To work out the timings we use a combination of typical traffic
              conditions, recent timing peformance, how likely we are to call at
              a stop and how many passengers we expect to use the stop. We vary
              all of these inputs by service and time of day to take into
              account conditions on different days and times of day.
            </Text>
            <Text>
              The frequency that stops are used is always changing. That has an
              impact on the timings of the service so we regularly re-time
              services. However, regulations prevent us doing this more than
              every 90 days so there can be situations where we know we have a
              timing issue but it takes quite a while to resolve.
            </Text>
          </Stack>
        </EmberCardSection>
      </EmberCard>
    </Container>
  </DriveLayout>
)

export default Page
